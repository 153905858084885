<script lang="ts" setup>
/**
 * props
 * ==================================================================
 */
const props = defineProps({
  name: {
    type: String,
    required: true,
    default: ''
  },
  size: {
    type: String,
    default: '18'
  }
})
</script>

<template>
  <div
    class="d-flex align-center"
    :data-test="$attrs['data-test']"
  >
    <v-icon
      :icon="props.name"
      :size="props.size"
    />
  </div>
</template>
